import { StorageService } from "./StorageService.service";
import {environment} from "../../environment/environment";

export class ApiService {
    public requireJWT: boolean = true;
    public session_data: any;
    storageService = new StorageService();
    public toggleJWT(status: boolean) {
        this.requireJWT = status;
    }
    public async getUserToken() {
        return this.storageService.getLocal('session_data');
    }

    public getBasicToken() {
        return `Basic ${btoa(environment.api.username + ':' + environment.api.password)}`;
    }

    public getHeaders(token?: any) {
        let headerObject: any = {
            Accept: 'application/json',
            'Content-type': 'application/json'
        };

        return headerObject;
    }
    public get(url: string, params?: any, reqOpts?: any, parseJson?: boolean): Promise<any> {
        return this.getUserToken().then(token => {
            if (!reqOpts) {
                reqOpts = {
                    headers: this.getHeaders()
                }
            }
            let config = {
                method: 'GET',
                headers: reqOpts.headers
            };
            let queryString = params ? `?${this.getQueryString(params)}` : '';
            return fetch(`${url}${queryString}`, config).then((response) => parseJson ? response.text() : response.json())
        })
    }

    public getBasic(url: string, params? : any): Promise<any> {
        return this.getUserToken().then(token => {
            let reqOpts : any = {
                headers: this.getHeaders()
            }
            reqOpts.headers.Authorization = this.getBasicToken();
            let config = {
                method: 'GET',
                headers: reqOpts.headers
            };
            let queryString = params ? `?${this.getQueryString(params)}` : '';
            return fetch(`${url}${queryString}`, config).then((response) => response.json());
        });
    }

    public post(url: string, body: any, params?: any, reqOpts?: any) {
        return this.getUserToken().then(token => {
            if (!reqOpts) {
                reqOpts = {
                    headers: this.getHeaders()
                }
            }
            let config = {
                method: 'POST',
                headers: reqOpts.headers,
                body: JSON.stringify(body)
            };
            let queryString = params ? `?${this.getQueryString(params)}` : '';
            return fetch(`${url}${queryString}`, config).then((response) => response.json())
        })
    }

    public postBasic(url: string, body: any, params?: any, reqOpts?: any) {
        return this.getUserToken().then(token => {
            if (!reqOpts) {
                reqOpts = {

                    headers: this.getHeaders()
                }
            }
            reqOpts.headers.Authorization = this.getBasicToken();
            let config = {
                method: 'POST',
                headers: reqOpts.headers,
                body: JSON.stringify(body)
            };
            let queryString = params ? `?${this.getQueryString(params)}` : '';
            return fetch(`${url}${queryString}`, config).then((response) => response.json())
        })
    }


    

    public put(url: string, body: any, params?: any, reqOpts?: any) {
        return this.getUserToken().then(token => {
            if (!reqOpts) {
                reqOpts = {
                    headers: this.getHeaders()
                }
            }
            let config = {
                method: 'PUT',
                headers: reqOpts.headers,
                body: JSON.stringify(body)
            };
            let queryString = params ? `?${this.getQueryString(params)}` : '';
            return fetch(`${url}${queryString}`, config).then((response) => response.json())
        })
    }
    public delete(url: string, params?: any, reqOpts?: any) {
        return this.getUserToken().then(token => {
            if (!reqOpts) {
                reqOpts = {
                    headers: this.getHeaders()
                }
            }
            let config = {
                method: 'DELETE',
                headers: reqOpts.headers
            };
            let queryString = params ? `?${this.getQueryString(params)}` : '';
            return fetch(`${url}${queryString}`, config).then((response) => response.json())
        })
    }
    private getQueryString(params: any) {
        let esc = encodeURIComponent;
        return Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
    }
}
