import React from "react";
import "./contact-us.scss";
import contactUs from '../../../../assets/images/Body/contactUs/Enmascarar grupo 5@2x.png';
import icon from '../../../../assets/images/Body/contactUs/Grupo 12@2x.png';
import lineOne from '../../../../assets/images/Body/contactUs/Trazado 9163@2x.png';
import lineTwo from '../../../../assets/images/Body/contactUs/Enmascarar grupo 6@2x.png';

import {ApiService} from "../../../../shared/services/ApiCrud.service";
import { environment } from "../../../../environment/environment";

interface Props {
    translator?: any
    show : boolean
}

class ContactComponent extends React.Component  <Props>{

    state = {
        name : "",
        business: "",
        email: "",
        message: "",
        phone: "",
        showConfirmationModal: false
    };

    public apiService = new ApiService();
    submitForm() {
        let body = {
            name: this.state.name,
            business: this.state.business,
            message: this.state.message,
            email: this.state.email,
            phoneNumber: this.state.phone
        }
        this.apiService.post( `${environment.api.server}${environment.api.context}/email/send`, body).then(result=>{
            if (result.code === 200) {

                this.setState({
                    name : "",
                    business: "",
                    email: "",
                    message: "",
                    phone: "",
                    showConfirmationModal: true
                });


            }
        })
    }


  render() {
    return (
<div className="animation-show-section" style={{opacity: this.props.show ? 1 : 0}}>
      <div className="container-contact"  >

          <div className="container-prices-area">
              <div className="form">
                  <div className="contact-us">
                      <img alt=""  className="imgContact" src={contactUs} />
                  </div>
              </div>
          </div>






        <form className="container-prices-area" id="Contacto" onSubmit={(event)=>event.preventDefault()} >
            <div className="form reduce">
                <div className="mybussiness-row">
                    <span className="title-contact-us">{this.props.translator('ContactTitle')}</span>
                </div>
                <div className="mybussiness-row"  >
                    <input  type="name" name="name" className="input-name"   pattern="[a-zA-Zà201čšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{3,80}"  required title="INTRODUCE TU NOMBRE COMPLETO"  placeholder={this.props.translator('ContactInput1')} value={this.state.name} onChange={(event)=>this.setState({name: event.target.value})} />
                    <input className="input-business" type="name"  required placeholder={this.props.translator('ContactInput2')} value={this.state.business} onChange={(event)=>this.setState({business: event.target.value})}/>
                </div>
                <div className="mybussiness-row">
                    <input className="input-mail" type="email" name="email" required placeholder={this.props.translator('ContactInput3')} value={this.state.email} onChange={(event)=>this.setState({email: event.target.value})}/>
                    <input className="input-phone" type="tel"  pattern="[0-9]{10}" title="INTRODUCE NUMERO DE CONTACTO A 10 DIGITOS" value={this.state.phone}  required placeholder={this.props.translator('ContactInput4')} onChange={(event)=>this.setState({phone: event.target.value})}/>
                </div>
                <div className="mybussiness-row">
                    <input className="input-ms" type="text"required placeholder={this.props.translator('ContactInput5')} value={this.state.message} onChange={(event)=>this.setState({message: event.target.value})}/>
                </div>
                <div className="mybussiness-button">
                    <button  className="table-button" value="Enviar"  onClick={()=>this.submitForm()}>{this.props.translator('ContactButton')}</button>
                </div>



            </div>
            {/* MODAL*/}
            {
                this.state.showConfirmationModal ?
                    <div className="modal-container">

                        <div id="popup" className="popup">
                            <img className="icon-modal" src={icon} alt="" />  <a href="#Contacto" className="close" onClick={()=>this.setState({showConfirmationModal: false})}>&times;</a>
                            <h2>¡Formulario enviado con éxito!</h2>
                            <p>Gracias por ponerte en contacto con nosotros, espera información</p>
                            <img className="lineOne" src={lineOne} alt="" />
                            <img className="lineTwo" src={lineTwo} alt="" />
                        </div>
                        <a href="#Contacto" className="close-popup">&nbsp;</a>
                    </div> : null
            }
        </form>




      </div>
</div>
    );
  }
}

export default ContactComponent;
