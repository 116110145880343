import React from "react";
import "./mobilScreen.scss";
import slider1 from "../../../../assets/images/Body/Mobil-screen/slider1.png";
import slider2 from "../../../../assets/images/Body/Mobil-screen/slider2.png";
import slider3 from "../../../../assets/images/Body/Mobil-screen/slider3.png";
import slider4 from "../../../../assets/images/Body/Mobil-screen/slider4.png";
import slider5 from "../../../../assets/images/Body/Mobil-screen/slider5.png";
import slider6 from "../../../../assets/images/Body/Mobil-screen/slider6.png";
import backgroundImage from "../../../../assets/images/Body/Mobil-screen/Enmascarar grupo 4@2x.png";
import cara from "../../../../assets/images/Body/Mobil-screen/phone.png";

interface Props {
    translator?: any
}


class MobilscreenComponent extends React.Component <Props> {

    intervalContainer: any;
    intervalTimer    : number   = 5000;
    sliderCount      : number   = 1;
    sliderTotal      : number   = 7;


    componentDidMount() {
      this.startSliderCounter();
    }

    startSliderCounter() {

        if (this.intervalContainer) {
            clearInterval(this.intervalContainer);
        }
        this.intervalContainer = setInterval(()=> {
            if (this.sliderCount === this.sliderTotal) {
                this.sliderCount = 0;
            }
            let slide = document.getElementById(`slide-${this.sliderCount    }-trigger`);
            if (slide) {
                slide.click();
            }
            this.sliderCount++;
        }, this.intervalTimer)
    }

    setActiveSlide(slideIndex:number) {
        this.sliderCount = slideIndex;
        this.startSliderCounter();
    }

    render() {
        return (
            <div>

                <section className="container container-hidden-scroll">
                    <div className="in-flex-box1 animated fadeInLeft">
                        <div className="containerSli">


                            <input type="radio" name="slider" id="slide-1-trigger" className="trigger" onClick={()=>this.setActiveSlide(1)}/>
                            <label className="btn" htmlFor="slide-1-trigger"></label>
                            <input type="radio" name="slider" id="slide-2-trigger" className="trigger"onClick={()=>this.setActiveSlide(2)}/>
                            <label className="btn" htmlFor="slide-2-trigger"></label>
                            <input type="radio" name="slider" id="slide-3-trigger" className="trigger" onClick={()=>this.setActiveSlide(3)}/>
                            <label className="btn" htmlFor="slide-3-trigger"></label>
                            <input type="radio" name="slider" id="slide-4-trigger" className="trigger" onClick={()=>this.setActiveSlide(4)}/>
                            <label className="btn" htmlFor="slide-4-trigger"></label>
                            <input type="radio" name="slider" id="slide-5-trigger" className="trigger" onClick={()=>this.setActiveSlide(5)}/>
                            <label className="btn" htmlFor="slide-5-trigger"></label>
                            <input type="radio" name="slider" id="slide-6-trigger" className="trigger" onClick={()=>this.setActiveSlide(6)}/>
                            <label className="btn" htmlFor="slide-6-trigger"></label>



                            <div className="slide-wrapper">
                                <div id="slide-role">
                                    <div className="slide slide-1">  <p className="title">{this.props.translator('SliderOneTitle')}</p>
                                        <p className="subtitle" >{this.props.translator('SliderOneSubTitle')}</p>
                                    </div>

                                    <div className="slide slide-2"><p className="title">{this.props.translator('SliderTwoTitle')}</p>
                                        <p className="subtitle" >{this.props.translator('SliderTwoSubTitle')}</p>
                                    </div>

                                    <div className="slide slide-3"><p className="title">{this.props.translator('SliderThreeTitle')}</p>
                                        <p className="subtitle" >{this.props.translator('SliderThreeSubTitle')}</p>
                                    </div>

                                    <div className="slide slide-4"><p className="title">{this.props.translator('SliderFourTitle')}</p>
                                        <p className="subtitle" >{this.props.translator('SliderFourSubTitle')}</p>
                                    </div>

                                    <div className="slide slide-5"><p className="title">{this.props.translator('SliderFiveTitle')}</p>
                                        <p className="subtitle" >{this.props.translator('SliderFiveSubTitle')}</p>
                                    </div>

                                    <div className="slide slide-6"><p className="title">{this.props.translator('SliderSixTitle')}</p>
                                        <p className="subtitle" >{this.props.translator('SliderSixSubTitle')}</p>
                                    </div>


                                </div>
                            </div>


                            {/*SLIDER PHONE */}
                            <div className="slide-wrapper wrapper-phone animation-delay-1 animated fadeInUp">

                                <img className="img-case animated fadeInUp" alt="" src={cara} />
                                {/* <div className="slider-container-wrapper"> */}
                                <div id="slide-role">

                                        <div className="slide slide-1 animation-delay-1 animated fadeInUp">
                                            <img className="img-ContenentPhone" alt="" src={slider1} />
                                        </div>

                                        <div className="slide slide-2">
                                            <img className="img-ContenentPhone" alt="" src={slider2} />
                                        </div>

                                        <div className="slide slide-3">
                                            <img className="img-ContenentPhone" alt="" src={slider3} />
                                        </div>

                                        <div className="slide slide-4">
                                            <img className="img-ContenentPhone" alt="" src={slider4} />
                                        </div>

                                        <div className="slide slide-5">
                                            <img className="img-ContenentPhone" alt="" src={slider5} />
                                        </div>

                                        <div className="slide slide-6">
                                            <img className="img-ContenentPhone" alt="" src={slider6} />
                                        </div>




                                    {/* </div> */}
                            </div>
                            </div>
                        </div>
                    </div>

                    {/* BOX 2*/}
                    <div className="in-flex-box2">
                        <div className="container-banner">

                            <img className="img-banner animated fadeIn" alt="" src={backgroundImage} />
                        </div>
                    </div>


{/* SLIDERRRRR*/}




                        </section>


            </div>







                            );
  }
}

export default MobilscreenComponent;
