import React from "react";
import "./membershipStyle.scss";
import linesImage from "../../../../assets/images/Body/Membership-screen/Trazado 9147@2x.png";
import lineBlue from "../../../../assets/images/Body/Membership-screen/line-blue.png";
import bannerImage from "../../../../assets/images/Body/Membership-screen/Trazado 9533@2x.png";
import grupo1035 from "../../../../assets/images/Body/Membership-screen/Grupo 1035@2x.png";
import yellowLineImage from "../../../../assets/images/Body/Membership-screen/Grupo 1018@2x.png";
import { Link } from "react-router-dom";



interface Props {
    translator?: any
    show : boolean
}


class PaymentComponent extends React.Component <Props> {
  render() {
    return (
        

        <div className=" containerHidden animation-show-prices  " style={{opacity: this.props.show ? 1 : 0}}>
            {/* CINCO*/}
            <section className="container-membership  " id="Planes">
                <div className="prices-title-area">
                    <img alt="" className={this.props.show ? "lines-image-container  animated-long zoomIn": "lines-image-container"} src={linesImage} />
                    <img  alt="" className={this.props.show ? "line-blue    animated-long zoomIn": "line-blue"} src={lineBlue} />
                    <p className="title5" >
                    
                        {this.props.translator('PlansTitle1')}
                       {/*this.props.translator('pop')*/}
                       <span> POP </span>
                        {this.props.translator('PlansTitle2')}
                        {/*¡Que tu negocio haga <span>POP</span> con estas promos!*/}
                    </p>


                    <p className="subtitle5">
                        {this.props.translator('PlansTitleText')} 
                    </p>

                </div>
            </section>

            {/* SEIS*/}
            
            <section className= {this.props.show ? " prices-container    animated fadeInUp": " prices-container"}>
                <div className="box-price">
                    <img alt="" className="banner-image-container" src={bannerImage} />
                    <img alt = "" className = "price-image" src= {this.props.translator('priceImage')}/>
                    <img alt="" className="yellow-line-container" src={yellowLineImage} />
                    <p className = "promo">{this.props.translator('PromotionText')}</p>
                    <span className="title6" >{this.props.translator('BasicTitle')}</span>
                    <span className="title-price" >{this.props.translator('BasicPrice')}<sup>{this.props.translator('BasicSub')}</sup></span>
                   
                    <div className="list-title">
                   
                        <ul>
                        
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BasicLi1')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BasicLi2')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BasicLi3')}</span></li>
                           

                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BasicoMitadTexto1')}</span></li>
                            <span id = "halfText">{this.props.translator('BasicoMitadTexto2')}</span>


                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BasicLi5')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BasicLi9')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BasicLi6')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BasicLi7')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BasicLi8')}</span></li>
                          


                            <li></li>

                        </ul>
                    </div>


                </div>

                <div className="box-price">
                       
                
                    
                    <p className = "promo">{this.props.translator('PromotionText')}</p>
                    <span className="title6" >{this.props.translator('EnterprisingTitle')}</span>
                    
                    <span className="title-price" >{this.props.translator('EnterprisingPrice')}<sup>{this.props.translator('EnterprisingSub')}</sup></span>
                    <div className="list-title">
                        <ul>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EnterprisingLi1')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EnterprisingLi2')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EnterprisingLi3')}</span></li>
                            

                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EmprendedorMitadTexto1')}</span></li>
                            <span id = "halfText">{this.props.translator('EmprendedorMitadTexto2')}</span>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EnterprisingLi5')}</span></li>
                           
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EnterprisingLi11')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EnterprisingLi7')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EnterprisingLi8')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EnterprisingLi9')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('EnterprisingLi10')}</span></li>
                            
                           

                            <li></li>
                            <li></li>
                        </ul>
                    </div>


                </div>

                <div className="box-price">
                
                
                    
                    <p className = "promo">{this.props.translator('PromotionText')}</p>
                    <span className="title6" >{this.props.translator('BusinessTitle')}</span>
                    <span className="title-price" >{this.props.translator('BusinessPrice')}<sup className="">{this.props.translator('BusinessSub')}</sup></span>
                    <div className="list-title">
                        <ul>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BusinessLi1')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BusinessLi2')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BusinessLi3')}</span></li>
                            
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('NegocioMitadTexto1')}</span></li>
                            <span id = "halfText">{this.props.translator('NegocioMitadTexto2')}</span>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BusinessLi8')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BusinessLi5')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BusinessLi7')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BusinessLi9')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BusinessLi10')}</span></li>
                            <li className="item" > <div className="icon-price" ></div> <span>{this.props.translator('BusinessLi11')}</span></li>
                            
                          


                            <li></li>

                        </ul>
                    </div>


                </div>
            </section>




            {/* SIETE*/}
            <section >

                <div >
                    <div className="text-terms">
                        <div className="box-button">
                        
                            <Link to = "/form" ><button className="button3">{this.props.translator('PlansButton')}</button></Link>
                        </div>
                        {this.props.translator('PlansInfo1')}<br/> {this.props.translator('PlansInfo2')}
                    </div>

                </div>
            </section>



        </div>
    );
  }
}


 {/*<img alt="" className={this.props.show ? "grupoLinea    animated-long zoomIn": "grupoLinea"} src={lineaAmarilla} />*/}
 {/*<img id = "bandera" alt="" className={this.props.show ? "tracing9533    animated-long zoomIn": "tracing9533"} src={banderaMorada} />*/} 
 {/*<img id= "botonOferta1" alt="" className={this.props.show ? "grupoBoton    animated-long zoomIn": "grupoBoton"} src={botonOferta} />*/} 
export default PaymentComponent;
