import React from "react";
import "./aboutscreen.scss";
import grupo333 from "../../../../assets/images/Body/About-screen/Grupo 333@2x.png";


interface Props {
    translator?: any
    show : boolean
}

class AboutScreenComponent extends React.Component<Props> {
  render() {
    return (

        <div className="animation-show-section" style={{opacity: this.props.show ? 1 : 0}}>
            <section className="ContainerAbout  " >
                <div className="flex-about" id="QueEs">
                    <p className={this.props.show ? "titleAbout animated fadeInUp": "titleAbout"}>{this.props.translator('AboutTitleOne')}<br/>
                        {this.props.translator('AboutTitleTwo')} </p>
                    <p className={this.props.show ? "subtitleAbout animated fadeInUp": "titleAbout"}>
                        {this.props.translator('AboutSubTitle')}
                    </p>
                    <img alt="" className={this.props.show ? "grupo333 animated fadeInUp": "titleAbout"} src={grupo333} />
                </div>

            </section>


        </div>
    );
  }
}

export default AboutScreenComponent;
