import React from "react";
import "./StepsStyle.scss";

import iconStepOne     from "../../../../assets/images/Body/Steps-screen/Grupo 409@2x.png";
import iconStepTwo     from "../../../../assets/images/Body/Steps-screen/Grupo 410@2x.png";
import iconStepThree   from "../../../../assets/images/Body/Steps-screen/Grupo 411@2x.png";
import imageThread     from "../../../../assets/images/Body/Steps-screen/Trazado 9243@2x.png";
import { Link } from "react-router-dom";


interface Props {
    translator?: any
    show : boolean
}

class StepsComponent extends React.Component <Props> {
  render() {
    return (
        <div className="animation-show-section" style={{opacity: this.props.show ? 1 : 0}}>
            <img alt="" className="imageThread" src={imageThread} />
            <section  >

                <div className="flexSteps">

                    <div className={this.props.show ? "flexSteps animated-long fadeInRight": "flexSteps"}>
                        <div className="title-steps">{this.props.translator('StepsTitle')}</div>

                        <div className="section1">
                            <img alt="" className="iconStepOne" src={iconStepOne} />
                            <div className="text-steps">
                                <Link to = "/form" >
                                {this.props.translator('StepsText1')} <a className="link-mna" href="">{this.props.translator('StepsButton')}</a>
                                </Link>
                            </div>
                        </div>

                        <div className={this.props.show ? "section2 animated-Vlong fadeInRight": "section2"}>
                            <img alt="" className="iconStepTwo" src={iconStepTwo } />
                            <div className="text-steps">
                                {this.props.translator('StepsText2')}
                            </div>
                        </div>

                        <div className="section3">
                            <img alt="" className="iconStepThree" src={iconStepThree} />
                            <div className="text-steps">
                                {this.props.translator('StepsText3')}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
  }
}
export default StepsComponent;
