import React from 'react';
import HeaderComponent from '../theme/components/Header/HeaderComponent';
import FooterComponent from '../theme/components/Footer/FooterComponent';
import ContactComponent from '../theme/components/Body/Contact-us/ContactUsComponent';
import AboutScreenComponent from '../theme/components/Body/About-screen/AboutScreenComponent';
import MobilscreenComponent from '../theme/components/Body/Mobil-screen/MobilscreenComponent';
import PersonalizeComponent from '../theme/components/Body/HowWork-personalize/PersonalizeComponent';
import ManagesComponent from '../theme/components/Body/HowWork-Manages/ManagesComponent';
import PaymentComponent from '../theme/components/Body/HowWork-payment/paymentComponent';
import MembershipComponent from '../theme/components/Body/membership-screen/MembershipComponent';
import FloatingButtonComponent from "../theme/components/Body/floating-button/FloatingButtonComponent";
import StepsComponent from "../theme/components/Body/Steps-screen/StepsComponent";
import '../App.scss';
import { Translation } from 'react-i18next';



class Home extends React.Component {
    translator:any;

    state = {
        scrollAbout: false,
        scrollPersonalize: false,
        scrollManages: false,
        scrollPayment: false,
        scrollMembership:false,
        scrollContact:false,
        scrollSteps: false

    }

    constructor(props: any) {
        super(props);
        this.checkScrollPosition = this.checkScrollPosition.bind(this);

    }

    initTranslator() {


    }

    componentDidMount() {
        window.addEventListener('scroll', this.checkScrollPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.checkScrollPosition);
    }

    checkIfTablet() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        return isTablet;
    }

    checkScrollPosition = (event:any) => {

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop

        if ((this.checkIfTablet() && winScroll >= 100) || (!this.checkIfTablet() && winScroll >= 230 )){
            this.setState({scrollAbout: true})
        }

        if ((this.checkIfTablet() && winScroll >= 600) || (!this.checkIfTablet() && winScroll >= 1100 )){
            this.setState({scrollPersonalize: true})
        }

        if ((this.checkIfTablet() && winScroll >= 1200) || (!this.checkIfTablet() && winScroll >= 1800 )){
            this.setState({scrollManages: true})
        }


        if ((this.checkIfTablet() && winScroll >= 1600) || (!this.checkIfTablet() && winScroll >= 2530 )){
            this.setState({scrollPayment: true})
        }

        if ((this.checkIfTablet() && winScroll >= 2200) || (!this.checkIfTablet() && winScroll >= 3350 )){
            this.setState({scrollMembership: true})
        }

        if ((this.checkIfTablet() && winScroll >= 3000) || (!this.checkIfTablet() && winScroll >= 4200 )){
            this.setState({scrollSteps: true})
        }


        if ((this.checkIfTablet() && winScroll >= 4000) || (!this.checkIfTablet() && winScroll >= 4800 )){
            this.setState({scrollContact: true})
        }




    }


    render() {



        return (

            <Translation>
                {
                    t => {
                        return   <div className="theme-light containerHidden">

                            <HeaderComponent  translator={t}/>
                            <MobilscreenComponent translator={t} />
                            <AboutScreenComponent translator={t} show = {this.state.scrollAbout} />
                            <PersonalizeComponent translator={t} show = {this.state.scrollPersonalize}/>
                            <ManagesComponent     translator={t} show = {this.state.scrollManages}/>
                            <PaymentComponent     translator={t} show = {this.state.scrollPayment}/>
                            <MembershipComponent  translator={t} show = {this.state.scrollMembership}/>
                            <StepsComponent       translator={t} show = {this.state.scrollSteps}/>
                            <ContactComponent     translator={t} show = {this.state.scrollContact}/>
                            <FloatingButtonComponent translator={t}/>
                            <FooterComponent translator={t}/>
                        </div>

                    }
                }
            </Translation>


        );
    }
}

export default Home;
