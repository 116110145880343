import React from "react";
import { Translation } from 'react-i18next';


import BodyUpComponent from "../theme/formComponents/body/BodyUpComponent";


import InformationForm from "../theme/formComponents/body/InformationForm";
import FooterComponent from "../theme/components2/Footer/FooterComponent";
import HeaderComponent from "../theme/formComponents/header/HeaderComponent";
import NewHeader from "../theme/formComponents/header/newHeader";
import Modal from "../theme/formComponents/modalWindow/modal";



export class Form extends React.Component{

    state = {
        name : "",
        business: "",
        email: "",
        message: "",
        phone: "",
        showConfirmationModal: false,
        ventanaAbierta: false

    };
    translator:any;
    constructor(props: any) {
        super(props);

            window.scroll(0,0);

    }

    initTranslator() {}

    render(){
        return(

            <Translation>
            {
              t => {
                  return <div className="relative">

                        <div>
                            <NewHeader translator={t}/>
                            {/*<HeaderComponent translator={t}/>*/}
                        </div> 

                     
                      <div>
                          <InformationForm translator = {t}/>
                      </div>
                       
                        <div>
                            <FooterComponent translator = {t}/>
                        </div>
                      

                        
                        
                        
                  </div>

              }
          } 
  
      

      </Translation>

        );
    }

}

export default Form;