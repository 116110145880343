import React from "react";
import "./ManagesStyle.scss";

import secondPart from "../../../../assets/images/Body/Manages-screen/Enmascarar grupo 8@2x.png";
import itemBuy from "../../../../assets/images/Body/Manages-screen/Grupo 351@2x.png";
import addButton from "../../../../assets/images/Body/Manages-screen/button@2x.png";
import firstPart from "../../../../assets/images/Body/Manages-screen/Rectangulo 71@2x.png";
import background from "../../../../assets/images/Body/Manages-screen/Enmascarar grupo 10@2x.png";

interface Props {
    translator?: any
    show : boolean
}



class ManagesComponent extends React.Component <Props> {
  render() {
    return (

        <div className="animation-show-section containerHidden" style={{opacity: this.props.show ? 1 : 0}}>

            <section className={this.props.show ? "containerManages animated fadeInUp ": "containerManages"}>
                <div className="flex-manages">
                    <img alt="" className="second-part-container" src={secondPart} />
                     <img alt="" className={this.props.show ? "background-image    animated-Vlong fadeInLeft": "background-image"} src={background} />
                    <img alt="" className={this.props.show ? "item-buy-image    animated-long fadeInLeft": "item-buy-image"} src={itemBuy} />
                    <img alt="" className={this.props.show ? "img-button    animated-long fadeInLeft": "img-button"} src={addButton} />
                      <img alt="" className="first-part-image" src={firstPart} />
                </div>



                <div className={this.props.show ? "flex-manages    animated fadeInRight": "flex-manages"}>
                    <div className="Title-management">{this.props.translator('ManagesTitle')}</div>

                    <div className="SubTitle-management">
                        {this.props.translator('ManagesTextOne')}
                    </div>

                    <div className="SubTitle-management2">
                        {this.props.translator('ManagesTextTwo')}
                    </div>

                    <div className="SubTitle-management2">
                      <strong> {this.props.translator('ManagesSubTitleThree')} </strong> <br/> {this.props.translator('ManagesTextThree')}
                    </div>


                </div>
            </section>

        </div>
    );
  }
}

export default ManagesComponent;
