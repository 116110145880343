import React from "react";
import "./BodyUp.css";
import imgOne from "../../../../src/assets/images2/Body/BodyUp/Grupo 429.png"

import imgSearch from "../../../../src/assets/images2/Body/BodyUp/Trazado 9254.png"

interface Props {
    translator?: any
}

class BodyUpComponent extends React.Component <Props> {
  render() {
    return (

        <div className="up-container" >
            <div className="text-up-container">
            <h1 className="up-title">{this.props.translator('TitleBody')}</h1>

                <div className="one-img-container">
                  <img className="one-img" src={imgOne} alt=""/>
                </div>

                {/* <p className="UpText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>



                    <div className="search">
                        <input type="text" className="searchTerm" placeholder="Búsqueda"/>
                            <button  >
                                <img className="img-search" src={imgSearch} alt=""/>
                            </button>
                </div> */}

            </div>



        </div>
    );
  }
}

export default BodyUpComponent;
