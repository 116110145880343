import React from "react";
import "./footer.scss";
import imageFooter from "../../../../src/assets/images2/footer/Grupo 430.png";
import fb from "../../../../src/assets/images2/footer/fb.png";
import tw from "../../../../src/assets/images2/footer/tw.png";
import ins from "../../../../src/assets/images2/footer/in.png";
import back from "../../../../src/assets/images2/footer/Trazado 9260.png";



interface Props {
    translator?: any
}


class FooterComponent extends React.Component <Props> {

    openWhatsApp() {
        window.open("https://wa.me/+5212294333247?text=Me%20interesa%20tener%20más%20información%20sobre%20Mi%20negocio%20app", "_blank")
    }

  render() {
    return (


      <div className="host-container" >

          <img className="img-back" src={back} alt=""/>
          <div className="container-question">
              <div className="image-question-container">
                  <div className="img-question"><img className="" src={imageFooter} alt=""/> </div>
              </div>
              <div className="question-container-footer">
                  <div className="container-quest"> <p>{this.props.translator('TextQuest')}</p> </div>
                  <div className="btn-question"> <button onClick={()=>this.openWhatsApp()} className="btn-Footer">{this.props.translator('Textbtn')} </button></div>
              </div>
          </div>

          <div className="hr-container"> <hr/></div>

          <div className="copy-container" >
              <p> {this.props.translator('FooterTextOne')}<a href="https://www.bwl.com.mx/">{this.props.translator('FooterTextTwo')}</a>{this.props.translator('FooterTextThree')}</p>
          </div>


          <div className="link-container" >
              <a><img className="imgLink" src={fb} alt=""/></a> <a><img className="imgLink" src={tw} alt=""/></a> <a><img className="imgLink" src={ins} alt=""/></a> </div>
      </div>
    );
  }
}

export default FooterComponent;
