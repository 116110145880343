import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import  Home  from './pages/Home';

import FAQs from './pages/FAQs';


import Form from './pages/Form';




const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/faqs" component={FAQs} />
            <Route exact path="/form" component={Form} />

            {/*<Route exact path="/servicios" component={Services} />
            <Route exact path="/videos" component={Videos} />*/}
           
        </Switch>
    </BrowserRouter>
)

export default Router
