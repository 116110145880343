import React from 'react';
import './newHeader.css';
import i18next from "i18next";
import log from '../../../../src/assets/images2/header/Grupo 12@2x.png'
import startMessage from '../../../../src/assets/images3/Grupo 1036.svg';
import blueCloud from '../../../../src/assets/images3/Nube.png';

interface Props {
    translator?: any
}

class NewHeader extends React.Component <Props> {
    state = {
        currentLanguage: false
    }

    changeLanguage() {
        this.setState({currentLanguage : !this.state.currentLanguage})
        if (this.state.currentLanguage) {
            i18next.changeLanguage("es")
        } else {
            i18next.changeLanguage("en")
        }
    }

    



    render(){

        return(

            <header>
                
            <div className="header-container">
                <img src= {blueCloud} className = "cloud"/>

        
            <div className='header'>
                <div className='box'>
                    <img src = {log} id = "logo"/>
                </div>
            <div className="main-title-container">

            <div className="title-container">
                   <h1>{this.props.translator("welcome")}</h1>
                   <img className="icon-start" src = {startMessage} />
                </div>
    
            </div>

           

            <div className="language-box">
                <a id = "pop"href = "https://mypopstoreapp.com/"><label id = "homw"> {this.props.translator("Link-home")}</label></a>
                <label id = "language" onClick={() => this.changeLanguage()}>{this.props.translator("Link-English")}</label>

            </div>
            
        </div>
        </div>

                <div className="container_header">
                    <div className="background_container">
                        <img src= {blueCloud} className = "cloud"/>
                    </div>
                    <div className="items-container">
                        <div className="logo-pop">
                            <img src = {log} />
                        </div>
                        <div className="title-buttons-container">
                            <div className="title-header-container">
                                <div className="title-page">{this.props.translator("welcome")}</div>
                                <div className="icon-start-container"><img className="icon-start" src = {startMessage} /></div>
                            </div>
                            <div className="buttons-header-container">
                                <a id = "pop"href = "https://mypopstoreapp.com/"><label id = "homw"> {this.props.translator("Link-home")}</label></a>
                                <label id = "language" onClick={() => this.changeLanguage()}>{this.props.translator("Link-English")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="title-response">
                        <div className="container-title-responsive">
                            <div className="title-page">{this.props.translator("welcome")}</div>
                            <div className="icon-start-container"><img className="icon-start" src = {startMessage} /></div>
                        </div>
                    </div>
                </div>
            </header>


        );
    }
}
export default NewHeader;