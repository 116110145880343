import React from "react";
import "./Accordion.scss";

interface Props {
    translator?: any
}


class AccordionComponent extends React.Component <Props> {
  render() {
    return (

        <div className="accordion-container">
        <div className="row">
            <div className="col">
                <div className="tabs">

                    <div className="tab">
                        {/*    <div className="Effect"> */}
                        <input className="accordion-input" type="checkbox" id="chck1"/>
                            <label className="tab-label" htmlFor="chck1">{this.props.translator('AcoordionInput1')}</label>
                            <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel1')}
                            </div>
                        </div>
                    {/*   </div>*/}
                <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck2"/>
                            <label className="tab-label" htmlFor="chck2">{this.props.translator('AcoordionInput2')}</label>
                        <hr/>
                            <div className="tab-content">
                                {this.props.translator('AcoordionLabel2')}
                            </div>
                    </div>

                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck3"/>
                        <label className="tab-label" htmlFor="chck3">{this.props.translator('AcoordionInput3')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel3')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck4"/>
                        <label className="tab-label" htmlFor="chck4">{this.props.translator('AcoordionInput4')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel4')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck5"/>
                        <label className="tab-label" htmlFor="chck5">{this.props.translator('AcoordionInput5')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel5')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck6"/>
                        <label className="tab-label" htmlFor="chck6">{this.props.translator('AcoordionInput6')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel6')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck7"/>
                        <label className="tab-label" htmlFor="chck7">{this.props.translator('AcoordionInput7')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel7')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel7-2')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel7-3')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck8"/>
                        <label className="tab-label" htmlFor="chck8"> {this.props.translator('AcoordionInput8')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel8')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel8-2')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck9"/>
                        <label className="tab-label" htmlFor="chck9"> {this.props.translator('AcoordionInput9')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel9')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel9-2')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck10"/>
                        <label className="tab-label" htmlFor="chck10">{this.props.translator('AcoordionInput10')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel10')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel10-2')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel10-3')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck11"/>
                        <label className="tab-label" htmlFor="chck11"> {this.props.translator('AcoordionInput11')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel11')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck12"/>
                        <label className="tab-label" htmlFor="chck12"> {this.props.translator('AcoordionInput12')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel12')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck13"/>
                        <label className="tab-label" htmlFor="chck13"> {this.props.translator('AcoordionInput13')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel13')}
                        </div>
                    </div>

                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck14"/>
                        <label className="tab-label" htmlFor="chck14">  {this.props.translator('AcoordionInput14')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel14')}
                        </div>
                    </div>



                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck15"/>
                        <label className="tab-label" htmlFor="chck15"> {this.props.translator('AcoordionInput15')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel15')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck16"/>
                        <label className="tab-label" htmlFor="chck16"> {this.props.translator('AcoordionInput16')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel16')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck17"/>
                        <label className="tab-label" htmlFor="chck17"> {this.props.translator('AcoordionInput17')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel17')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck18"/>
                        <label className="tab-label" htmlFor="chck18"> {this.props.translator('AcoordionInput18')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel18')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck19"/>
                        <label className="tab-label" htmlFor="chck19"> {this.props.translator('AcoordionInput19')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel19')}
                        </div>
                    </div>

                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck20"/>
                        <label className="tab-label" htmlFor="chck20"> {this.props.translator('AcoordionInput20')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel20')}
                        </div>
                    </div>


                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck21"/>
                        <label className="tab-label" htmlFor="chck21"> {this.props.translator('AcoordionInput21')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel21')}
                        </div>
                    </div>

                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck22"/>
                        <label className="tab-label" htmlFor="chck22"> {this.props.translator('AcoordionInput22')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel22')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel22-2')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel22-3')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel22-4')}
                            <br/>
                            <br/>
                            {this.props.translator('AcoordionLabel22-5')}
                        </div>
                    </div>

                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck23"/>
                        <label className="tab-label" htmlFor="chck23"> {this.props.translator('AcoordionInput23')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel23')}
                        </div>
                    </div>

                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck24"/>
                        <label className="tab-label" htmlFor="chck24"> {this.props.translator('AcoordionInput24')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel24')}
                        </div>
                    </div>

                    <div className="tab">
                        <input className="accordion-input" type="checkbox" id="chck25"/>
                        <label className="tab-label" htmlFor="chck25"> {this.props.translator('AcoordionInput25')}</label>
                        <hr/>
                        <div className="tab-content">
                            {this.props.translator('AcoordionLabel25')}
                        </div>
                    </div>


                </div>
            </div>
        </div>
        </div>
    );
  }
}

export default AccordionComponent;
