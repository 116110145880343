import React from "react";
import "./floatingButton.scss";
import wa from "../../../../assets/images/Body/About-screen/wa.png";

interface Props {
    translator?: any

}

class FloatingButtonComponent extends React.Component<Props> {

  openWhatsApp() {
      window.open("https://wa.me/+5212294333247?text=Me%20interesa%20tener%20más%20información%20sobre%20MyPopStore", "_blank")
  }

  render() {
    return (

        <div className="container-btn">
            <input  type="checkbox" id="btn-more"/>

                <div className="btn-more">

                    <label htmlFor="btn-more" className="icon-mas2">
                        <img  className="wa-icon" src={wa} alt="" />
                       <a className="style-link"    href="#wa"  onClick={()=>this.openWhatsApp()}><span>{this.props.translator('wa-text')}</span></a> </label>
                </div>
        </div>
    );
  }
}

export default FloatingButtonComponent;
