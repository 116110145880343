import React from "react";
import HeaderComponent from "../theme/components2/Header/HeaderComponent";
import FooterComponent from "../theme/components2/Footer/FooterComponent";
import BodyUpComponent from "../theme/components2/Body/BodyUpComponent";
import './FAQs.scss'
import AccordionComponent from "../theme/components2/Body/Accordion/AccordionComponent";
import { Translation } from 'react-i18next';

class FAQs extends React.Component {
    translator:any;
    constructor(props: any) {
        super(props);

            window.scroll(0,0);

    }

    initTranslator() {}

    render() {

        return (

            <Translation>
                {
                    t => {


        return <div className="relative">

            <div > 
        <HeaderComponent  translator={t}/>
            </div>


            <div>
            <BodyUpComponent translator={t}/>
            </div>

            <div >
                <AccordionComponent translator={t}/>
            </div>
            <div >
                <FooterComponent translator={t}/>
            </div>


        </div>

                    }
                }
            </Translation>


        );
        }
}

export default FAQs;
