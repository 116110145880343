import React from "react";
import "./PersonalizeStyle.scss";
import lineImage from "../../../../assets/images/Body/HowWork-screen/Trazado 9135@2x.png";
import ringsImage from "../../../../assets/images/Body/HowWork-screen/Grupo 350@2x.png";
import bagImage from "../../../../assets/images/Body/HowWork-screen/Grupo 300@2x.png";
import headerAppImage from "../../../../assets/images/Body/HowWork-screen/Grupo 352@2x.png";
import iconProducts from "../../../../assets/images/Body/HowWork-screen/Grupo 338@2x.png";
import iconCoupons from "../../../../assets/images/Body/HowWork-screen/Grupo 336@2x.png";
import iconOrders from "../../../../assets/images/Body/HowWork-screen/Grupo 337@2x.png";
import iconDashboard from "../../../../assets/images/Body/HowWork-screen/Grupo 419.png";
import phone2 from "../../../../assets/images/Body/HowWork-screen/phone2.png";


interface Props {
    translator?: any
    show : boolean
}

class PersonalizeComponent extends React.Component <Props> {
  render() {
    return (
        <div className="animation-show-section" style={{opacity: this.props.show ? 1 : 0}}>
            <section className="container-Personalize" id="TuApp">

                <div className={this.props.show ? "flex-personalize animated fadeInLeft": "flex-personalize"}>
                    <img alt="" className={this.props.show ? "line-image-container animated-Vlong fadeInLeft": "line-image-container"} src={lineImage} />
                    <img alt="" className={this.props.show ? "bag-image-container animated-long fadeInLeft": "bag-image-container"} src={bagImage} />
                    <img alt="" className={this.props.show ? "rings-image-container animated-long fadeInLeft": "rings-image-container"} src={ringsImage} />
                    <img alt="" className="header-app-container" src={headerAppImage} />

                </div>




                <div className="flex-personalize">

                    <div className={this.props.show ? "container-Personalize2 animated-long fadeInRight": "container-Personalize2"}>
                        <div className="title-personalize">{this.props.translator('PersonalizedTitle')} </div>

                        <div className="section">
                            <img alt="" className="icon-products" src={iconProducts} />
                            <div className="text-section">
                                <strong>{this.props.translator('PersonalizedSubTitleOne')}</strong> <br/>
                                        {this.props.translator('PersonalizedTextOne')}
                            </div>
                        </div>

                        <div className="section">
                            <img alt="" className="icon-coupons" src={iconCoupons} />
                            <div className="text-section">
                                  <strong>{this.props.translator('PersonalizedSubTitleTwo')}</strong>  <br/>
                                            {this.props.translator('PersonalizedTextTwo')}
                            </div>
                        </div>

                        <div className="section">
                            <img alt="" className="icon-orders" src={iconOrders} />
                            <div className="text-section">
                                <strong>{this.props.translator('PersonalizedSubTitleThree')}</strong> <br/>
                                        {this.props.translator('PersonalizedTextThree')}

                            </div>
                        </div>

                        <div className="section">
                             <img alt="" className="icon-coupons" src={iconDashboard} />
                            <div className="text-section">
                                 <strong>{this.props.translator('PersonalizedSubTitleFour')}</strong>  <br/>
                                         {this.props.translator('PersonalizedTextFour')}
                            </div>
                        </div>

                    </div>
                    <img alt="" className="phone2" src={phone2} />
                </div>
            </section>

        </div>
    );
  }
}
export default PersonalizeComponent;
