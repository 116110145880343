import React from "react";
import "./paymentStyle.scss";
import paymentCheckIcon from "../../../../assets/images/Body/Payment-screen//check-icon.png";
import backgroundImage from "../../../../assets/images/Body/Payment-screen//Enmascarar grupo 6@2x.png";
import confirmOrderImage from "../../../../assets/images/Body/Payment-screen//payment5.png";
import sendImage from "../../../../assets/images/Body/Payment-screen/payment4.png";
import paymentImage from "../../../../assets/images/Body/Payment-screen/payment2.png";
import discountCodeImage from "../../../../assets/images/Body/Payment-screen/payment3.png";
import invoiceQuestionImage from "../../../../assets/images/Body/Payment-screen/payment1.png";

interface Props {
    translator?: any
    show : boolean
}


class PaymentComponent extends React.Component <Props> {
  render() {
    return (

        <div className="animation-show-section" style={{opacity: this.props.show ? 1 : 0}}>



            <section className="payment-container ">
                <div className={this.props.show ? "payment-area    animated-long zoomInLeft ": "payment-area"}>

                    <div className="payment-area-container">
                        <div className="title-payment">
                            {this.props.translator('PaymentTitle')}
                        </div>

                        <div className="subtitle-payment">
                        <div>{this.props.translator('PaymentText')}</div>

                            <div></div>
                            <div></div>
                        <div>{this.props.translator('PaymentSubTitle')}</div>

                        <div className="container4">
                            <div className="child">
                                <div className="payment-check-icon"><img alt="" className="payment-icon" src={paymentCheckIcon} /></div>
                                <span>{this.props.translator('PaymentLi1')}</span>

                            </div>
                            <div className="child">
                                <div className="payment-check-icon"><img alt="" className="payment-icon" src={paymentCheckIcon} /></div>
                                <span>{this.props.translator('PaymentLi2')}</span>

                            </div>
                            <div className="child">
                                <div className="payment-check-icon"><img alt="" className="payment-icon" src={paymentCheckIcon} /></div>
                                <span>{this.props.translator('PaymentLi3')}</span>

                            </div>
                            <div className="child">
                                <div className="payment-check-icon"><img alt="" className="payment-icon" src={paymentCheckIcon} /></div>
                                <span>{this.props.translator('PaymentLi4')}</span>

                            </div>

                            <div className="child">
                                <div className="payment-check-icon"><img alt="" className="payment-icon" src={paymentCheckIcon} /></div>
                                <span>{this.props.translator('PaymentLi5')}</span>

                            </div>

                            <div className="child">
                                <div className="payment-check-icon"><img alt="" className="payment-icon" src={paymentCheckIcon} /></div>
                                <span>{this.props.translator('PaymentLi6')}</span>
                            </div>
                        </div>
                        </div>

                    </div>

                </div>



                <div className= {this.props.show ? "payment-area    animated zoomIn": "payment-area"}>
                    <div className="payment-images" >
                        <img alt="" className={this.props.show ? "payment-cloud-blue   animated zoomIn": "payment-cloud-blue"} src={backgroundImage} />
                        <img alt="" className={this.props.show ? "image confirm     animated-long zoomIn ": "image confirm "} src={confirmOrderImage} />
                        <img alt="" className={this.props.show ? "image deliver    animated-long zoomIn ": "image deliver"} src={sendImage} />
                        <img alt="" className={this.props.show ? "image payment    animated-long zoomIn ": "image payment"} src={paymentImage} />
                        <img alt="" className={this.props.show ? "image code    animated-long zoomIn ": "image code"} src={discountCodeImage} />
                        <img alt="" className={this.props.show ? "image invoice    animated-long zoomIn ": "image invoice"} src={invoiceQuestionImage} />
                    </div>
                </div>
            </section>




        </div>
    );
  }
}

export default PaymentComponent;
